import "./App.css";
import React, { useState } from "react";
import Calculator from "./Calculator/index.tsx";
import Editor from "./Editor";
import axios from "axios";
import Button from "@mui/material/Button";

// const api_url = "http://localhost:6789/apinume";
const api_url = "https://animeguess.com:6790/apinume";
// const api_url = 'http://animeguess.com:6789/apinume'

function App() {
  const [unlocked, setUnlocked] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  function unlock() {
    axios
      .post(api_url + "/checkpassword", {
        username: username,
        password: password,
      })
      .then(function (response) {
        console.log("The choice of Steins; Gate: ", response.data.status);
        if (response.data.status == 1) {
          setUnlocked(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      {/* {!unlocked ? (
        <div
          style={{
            marginBottom: 60,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontStyle: "italic",
              marginBottom: 5,
            }}
          >
            Tên đăng nhập
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              value={username}
              onPressEnter={() => unlock()}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontStyle: "italic",
              marginBottom: 5,
              marginTop: 10,
            }}
          >
            Mật khẩu
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="password"
              value={password}
              onPressEnter={() => unlock()}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => unlock()}
              style={{ marginTop: 20, marginBottom: 40 }}
              variant="outlined"
            >
              Mở cổng
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontStyle: "italic",
              marginBottom: 5,
              marginTop: 10,
            }}
          >
            Liên hệ SĐT: 0987542829
          </div>
          <a
            style={{
              display: "flex",
              justifyContent: "center",
              fontStyle: "italic",
              marginBottom: 5,
              marginTop: 10,
            }}
            href="https://zalo.me/0987542829"
          >
            Zalo Link
          </a>
        </div>
      ) : (
        <div></div>
      )}
      {unlocked ? ( */}
      <div>
        <Calculator></Calculator>
        <Editor></Editor>
      </div>
      {/* ) : (
        <div></div>
      )} */}
    </>
  );
}

export default App;
