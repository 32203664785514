import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Autocomplete from "@mui/material/Autocomplete";

const api_url = "https://animeguess.com:6790/apinume";
// const api_url = 'http://animeguess.com:6789/apinume'

function Editor() {
  const [unlocked, setUnlocked] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const element_list = [
    "Đường Đời",
    "Nội Tâm",
    "Nhân Cách",
    "Sứ Mệnh",
    "Thái Độ",
    "Nội Cảm",
    "Số Ngày Sinh",
    "Số Lặp",
    "Trưởng Thành",
    "Chặng Đường Đời Đỉnh 1",
    "Chặng Đường Đời Đỉnh 2",
    "Chặng Đường Đời Đỉnh 3",
    "Chặng Đường Đời Đỉnh 4",
    "Số bổ sung",
    "CSKN NT-NC",
    "CSKT ĐĐ-SM",
    "Chỉ số Năm cá nhân",
    "Chỉ số tháng",
  ];
  const list = [
    {
      label: "Đường Đời",
      dbName: "DuongDoi",
    },
    {
      label: "Sứ Mệnh",
      dbName: "SuMenh",
    },
    {
      label: "CSKN ĐĐ-SM",
      dbName: "DuongDoiSuMenh",
    },
    {
      label: "Linh Hồn",
      dbName: "NoiTam",
    },
    {
      label: "Số Ngày Sinh",
      dbName: "SoNgaySinh",
    },
    {
      label: "Số Cân Bằng",
      dbName: "SoCanBang",
    },
    {
      label: "Nhân Cách",
      dbName: "NhanCach",
    },
    {
      label: "CSKN LH-NC",
      dbName: "NoiTamNhanCach",
    },
    {
      label: "Trưởng Thành",
      dbName: "TruongThanh",
    },

    {
      label: "Chặng Đường Đời Đỉnh 1",
      dbName: "ChangDuongDoiDinh1",
    },
    {
      label: "Chặng Đường Đời Đỉnh 2",
      dbName: "ChangDuongDoiDinh2",
    },
    {
      label: "Chặng Đường Đời Đỉnh 3",
      dbName: "ChangDuongDoiDinh3",
    },
    {
      label: "Chặng Đường Đời Đỉnh 4",
      dbName: "ChangDuongDoiDinh4",
    },
    {
      label: "Chỉ Số Thách Thức",
      dbName: "ChiSoThachThuc",
    },
    {
      label: "Số thiếu",
      dbName: "SoBoSung",
    },
    {
      label: "Sức Mạnh Tiềm Thức",
      dbName: "SucManhTiemThuc",
    },
    {
      label: "Tư Duy Lý Trí",
      dbName: "TuDuyLyTri",
    },
    {
      label: "Thái Độ",
      dbName: "ThaiDo",
    },
    {
      label: "Đam Mê",
      dbName: "NoiCam",
    },
    {
      label: "Số Lặp",
      dbName: "SoLap",
    },
    {
      label: "Chỉ số Năm cá nhân",
      dbName: "ChiSoNamCaNhan",
    },
    {
      label: "Chỉ Số Tháng Cá nhân",
      dbName: "ChiSoThang",
    },
    {
      label: "Chỉ Số Ngày Cá Nhân",
      dbName: "ChiSoNgay",
    },
  ];

  const [, updateState] = React.useState();
  const number_lst = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 22, 33];
  const [profile_lst, setProfileLst] = useState([]);
  const [attribute_name, setAttributeName] = useState("DuongDoi");
  const [open, setOpen] = React.useState(false);

  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    getProfiles();
  }, [attribute_name]);

  useEffect(() => {
    getProfiles();
  }, []);

  function unlock() {
    axios
      .post(api_url + "/checkPassword", {
        username: "hoangyenngoc",
        password: password,
      })
      .then(function (response) {
        console.log("The choice of Steins; Gate: ", response.data.status);
        if (response.data.status == 1) {
          setUnlocked(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {}, [profile_lst]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function onChange(name, value) {
    setAttributeName(value.dbName);
  }

  function escape_sql(str) {
    return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
      switch (char) {
        case "\0":
          return "\\0";
        case "\x08":
          return "\\b";
        case "\x09":
          return "\\t";
        case "\x1a":
          return "\\z";
        case "\n":
          return "\\n";
        case "\r":
          return "\\r";
        case '"':
        case "'":
        case "\\":
        case "%":
          return "\\" + char; // prepends a backslash to backslash, percent,
        // and double/single quotes
        default:
          return char;
      }
    });
  }

  function getProfiles() {
    axios
      .post(api_url + "/getProfilesByAttributeName", {
        AttributeName: attribute_name,
      })
      .then(function (response) {
        console.log(response);
        var data = response.data;
        setProfileLst(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function updateProfile(attribute_name, attribute_id) {
    var profile = profile_lst.find(
      (x) => x.AttributeName == attribute_name && x.AttributeID == attribute_id
    );
    if (profile) {
      var new_text = profile.Text;
      new_text = escape_sql(new_text);
      axios
        .post(api_url + "/updateProfile", {
          AttributeName: attribute_name,
          AttributeID: attribute_id,
          new_text: new_text,
        })
        .then(function (response) {
          console.log("The choice of Steins; Gate: ", response.data.status);
          if (response.data.status == 1) {
            setOpen(true);
            setTimeout(() => setOpen(false), 2000);
            getProfiles();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function onChangeText(attribute_name, attribute_id, new_text) {
    var profiles = profile_lst;
    var profile = profile_lst.find(
      (x) => x.AttributeName == attribute_name && x.AttributeID == attribute_id
    );
    profile.Text = new_text;
    setProfileLst(profiles);
    forceUpdate();
  }

  return (
    <div style={{ textAlign: "center" }} className="calculator-container">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        close={handleClose}
        message="Lưu báo cáo thành công"
      />
      <>
        {!unlocked ? (
          <div
            style={{
              marginBottom: 60,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontStyle: "italic",
                marginBottom: 5,
              }}
            >
              Tên đăng nhập
            </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                value={username}
                onPressEnter={() => unlock()}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontStyle: "italic",
                marginBottom: 5,
                marginTop: 10,
              }}
            >
              Mật khẩu
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                type="password"
                value={password}
                onPressEnter={() => unlock()}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => unlock()}
                style={{ marginTop: 20, marginBottom: 40 }}
                variant="outlined"
              >
                Chỉnh sửa
              </Button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {unlocked ? (
          <div>
            <Grid container justifyContent="center">
              <Autocomplete
                options={list}
                sx={{ width: "30%" }}
                renderInput={(params) => <TextField {...params} />}
                onChange={onChange}
              />
            </Grid>

            {number_lst.map((item) => {
              var profile = profile_lst.find(
                (x) =>
                  x.AttributeName == attribute_name && x.AttributeID == item
              );
              var text = "";
              if (profile) text = profile.Text;
              return (
                <div>
                  <div
                    style={{ marginTop: 30, marginBottom: 30, fontSize: 20 }}
                  >
                    {item}
                  </div>
                  {/* <TextField
                        sx={{ display: "flex", justifyContent: "center", marginLeft: 20, width:"80%" }}
                        value={text}
                        onChange={(e) => onChangeText(attribute_name, item, e.target.value)}
                        onBlur={(e) => updateProfile(attribute_name, item, e.target.value)}
                        multiline /> */}

                  <Card variant="outlined">
                    <React.Fragment>
                      <CardContent>
                        <Grid container justifyContent="center">
                          <TextField
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              width: "80%",
                            }}
                            value={text}
                            onChange={(e) =>
                              onChangeText(attribute_name, item, e.target.value)
                            }
                            // onBlur={(e) => updateProfile(attribute_name, item, e.target.value)}
                            multiline
                          />
                        </Grid>
                      </CardContent>
                      <Grid container justifyContent="center">
                        <CardActions style={{ marginBottom: 25 }}>
                          <Button
                            onClick={(e) =>
                              updateProfile(
                                attribute_name,
                                item,
                                e.target.value
                              )
                            }
                            size="small"
                          >
                            Lưu
                          </Button>
                        </CardActions>
                      </Grid>
                    </React.Fragment>
                  </Card>
                </div>
              );
            })}
          </div>
        ) : (
          <div></div>
        )}
      </>
    </div>
  );
}

export default Editor;
