import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import viLocale from "date-fns/locale/vi";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import * as docx from "docx";

// const api_url = "http://animeguess.com:6789/apinume";
const api_url = "https://animeguess.com:6790/apinume";
// const api_url = "http://localhost:6789/apinume";

interface Profile {
  Text: string;
}
function Calculator() {
  const [inputBirth, setBirthDate] = useState(parseDate(new Date()));
  const [inputName, setName] = useState("");
  const [inputFirstName, setFirstName] = useState("");
  const [inputLastName, setLastName] = useState("");
  const [inputDayMonth, setDayMonth] = useState(parseDayMonth(new Date()));
  const [inputYear, setYear] = useState(-1);
  const [inputMonth, setMonth] = useState(-1);
  const [inputDay, setDay] = useState(-1);
  const [inputYearStr, setYearStr] = useState("");
  const [inputMonthStr, setMonthStr] = useState("");
  const [inputDayStr, setDayStr] = useState("");
  const [shortYear, setShortYear] = useState(-1);
  const [shortMonth, setShortMonth] = useState(-1);
  const [shortDay, setShortDay] = useState(-1);
  const [duongdoi, setDuongDoi] = useState(-1);
  const [profile, setProfile] = useState("");
  const [allProfiles, setAllProfiles] = useState<Profile[]>([]);

  const [sumenh, setSuMenh] = useState(-1);
  const [sumenh_profile, setSuMenhProfile] = useState("");

  const [noitam, setNoiTam] = useState(-1);
  const [nhancach, setNhanCach] = useState(-1);
  const [thaido, setThaiDo] = useState(-1);
  const [songaysinh, setSoNgaySinh] = useState(-1);
  const [solap, setSoLap] = useState(-1);
  const [noicam, setNoiCam] = useState("");
  const [truongthanh, setTruongThanh] = useState(-1);
  const [changduongdoi, setChangDuongDoi] = useState([-1, -1, -1, -1]);
  const [moctuoi, setMocTuoi] = useState([-1, -1, -1, -1]);

  const [sobosung, setSoBoSung] = useState("");
  const [sucmanhtiemthuc, setSucManhTiemThuc] = useState(-1);

  const [chisoketnoiNTNC, setChiSoKetNoiNTNC] = useState(-1);
  const [chisoketnoiSMDD, setChiSoKetNoiSMDD] = useState(-1);
  const [namcanhan, setNamCaNhan] = useState(-1);
  const [chisothang, setChiSoThang] = useState(-1);
  const [chisothang1, setChiSoThang1] = useState(-1);
  const [chisothang2, setChiSoThang2] = useState(-1);
  const [chisothachthuc, setChiSoThachThuc] = useState<any>([]);
  const [socanbang, setSoCanBang] = useState(-1);
  const [chisongay, setChiSoNgay] = useState(-1);
  const [tuduylytri, setTuDuyLyTri] = useState(-1);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setDayMonth(inputBirth.slice(5));
    setDate(inputBirth);
    updateAllNumbers();
  }, [inputBirth]);

  useEffect(() => {
    updateAllNumbers();
  }, [inputName]);

  useEffect(() => {
    updateAllNumbers();
  }, [inputDayMonth]);

  useEffect(() => {
    updateAllNumbers();
  }, [thaido]);

  function setDate(date) {
    setYear(parseInt(date.substring(0, 4)));
    setMonth(parseInt(date.substring(5, 7)));
    setDay(parseInt(date.substring(8, 10)));
    setYearStr(date.substring(0, 4));
    setMonthStr(date.substring(5, 7));
    setDayStr(date.substring(8, 10));
  }

  function parseDate(date: Date) {
    return (
      String(date.getUTCFullYear()) +
      "-" +
      ("0" + String(date.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + String(date.getUTCDate())).slice(-2)
    );
  }

  function parseDayMonth(date: Date) {
    return (
      String("0" + String(date.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + String(date.getUTCDate())).slice(-2)
    );
  }

  function get1to9(str: string) {
    return merge1122(_calculateString(str));
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function removeVietnameseTones(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(
      /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
      " "
    );
    // Remove numbers
    str = str.replace(/[0-9]/g, "");
    return str;
  }

  function _onChangeBirth(evt) {
    var birthDate = moment(evt).format("YYYY/MM/DD");
    setBirthDate(birthDate);
  }

  function _onChangeFirstName(evt) {
    var name = removeVietnameseTones(evt.target.value);
    setFirstName(name);
    setName(inputLastName + " " + name);
  }

  function _onChangeLastName(evt) {
    var name = removeVietnameseTones(evt.target.value);
    setLastName(name);
    setName(name + " " + inputFirstName);
  }

  function updateAllNumbers() {
    setDuongDoi(calculateDuongDoi());
    setSuMenh(get1to9(inputName));
    setNoiTam(calculateNoiTam(inputName));
    setNhanCach(calculateNhanCach(inputName));
    setThaiDo(calculateThaiDo(inputDayMonth));
    setSoNgaySinh(calculateSoNgaySinh());
    setSoLap(
      calculateSoLap(
        _calculateString(inputBirth),
        _calculateString(inputName),
        calculateNoiTam(inputName),
        calculateNhanCach(inputName),
        calculateThaiDo(inputDayMonth),
        calculateSoNgaySinh()
      )
    );
    setNoiCam(calculateNoiCam(inputName));
    setTruongThanh(
      calculateTruongThanh(
        _calculateString(inputName),
        _calculateString(inputBirth)
      )
    );
    setChangDuongDoi(calculateChangDuongDoi(calculateThaiDo(inputDayMonth)));
    setMocTuoi(calculateMocTuoi(_calculateString(inputBirth)));
    setSoBoSung(calculateSoBoSung(inputName));
    setSucManhTiemThuc(calculateSucManhTiemThuc(calculateSoBoSung(inputName)));
    setChiSoThachThuc(calculateChiSoThachThuc(inputBirth));
    setSoCanBang(calculateSoCanBang());
    setChiSoKetNoiNTNC(
      calculateChiSoKetNoiNTNC(
        calculateNoiTam(inputName),
        calculateNhanCach(inputName)
      )
    );
    setChiSoKetNoiSMDD(
      calculateChiSoKetNoiSMDD(
        _calculateString(inputName),
        _calculateString(inputBirth)
      )
    );
    setNamCaNhan(calculateNamCaNhan());
    setTuDuyLyTri(calculateTuDuyLyTri());
    setChiSoThang(calculateChiSoThang(calculateNamCaNhan()));
    setChiSoThang1(calculateChiSoThang1(calculateNamCaNhan()));
    setChiSoThang2(calculateChiSoThang2(calculateNamCaNhan()));
    setChiSoNgay(calculateChiSoNgay(calculateChiSoThang(calculateNamCaNhan())));
  }

  function mergeNumbers(arr) {
    return get1to9(mergeNumbersToArr(arr));
  }

  function mergeNumbersToArr(arr) {
    var str = "";
    arr.forEach((element) => {
      str += String(element);
    });
    return str;
  }

  function toLC(str: String) {
    let lowerCaseStr = "";
    if (str) lowerCaseStr = str.toString().toLowerCase();
    return lowerCaseStr;
  }

  function merge1122(num) {
    if (num == 11) return 2;
    if (num == 22) return 4;
    if (num == 33) return 6;
    return num;
  }

  function calculateDanhDo(str: String) {
    let lowerCaseStr = toLC(str);
    return Array.prototype.map.call(lowerCaseStr, (ch) => {
      return String(get1to9(ch));
    });
  }

  function _calculateString(str: string) {
    //calculate Su Menh
    if (!str || str == "") {
      return -1;
    }
    function isCoreValue(n: number) {
      return (n >= 1 && n <= 9) || n == 11 || n == 22 || n == 33;
    }

    function sumCharacters(str: string) {
      let lowerCaseStr = toLC(str);
      return Array.prototype.map
        .call(lowerCaseStr, (ch) => {
          if (ch >= "a" && ch <= "z") {
            return Number(ch.charCodeAt(0) - "a".charCodeAt(0)) + 1;
          }
          return ch >= "0" && ch <= "9" ? Number(ch) : 0;
        })
        .reduce((sum, current) => sum + current, 0);
    }

    function calculateCore(str: string) {
      if (isCoreValue(Number(str))) return str;
      const sumBirth: number = sumCharacters(str);

      if (sumBirth == 0) {
        return -1;
      }
      return isCoreValue(sumBirth) ? sumBirth : calculateCore(String(sumBirth));
    }
    //Added March 20
    return calculateCore(str);
  }

  function get1122AsResult(n: number) {
    if ((n >= 1 && n <= 9) || n == 11 || n == 22 || n == 33) return n;
    else return _calculateString(String(n));
  }

  function isVowel(x, prev, next) {
    if (x != "y" && x != "Y") return isNormalVowel(x);
    else {
      if (isNormalVowel(prev) || isNormalVowel(next)) {
        console.log("Y not a vowel");
        return false;
      } else {
        console.log("Y is a vowel");
        return true;
      }
    }
  }

  function isNormalVowel(str) {
    return /[aeiouAEIOU]/.test(str);
  }

  function calculateDuongDoi() {
    const str =
      _calculateString(inputDayStr) +
      _calculateString(inputMonthStr) +
      _calculateString(inputYearStr);
    return _calculateString(str);
  }

  // function calculateNoiTam(str: string) {
  //     if (!str || str == '') {
  //         return -1;
  //     }
  //     function isCoreValue(n: number) {
  //         return (n >= 1 && n <= 9) || n == 11 || n == 22 || n == 33;
  //     }

  //     function sumCharacters(str: string) {
  //         return Array.prototype.map.call((str || '').toLowerCase(), (ch, index) => {
  //             var length = str.length;
  //             var prev = ""
  //             if (index != 0)
  //                 prev = str.charAt(index - 1);

  //             var next = ""
  //             if (index != length - 1)
  //                 next = str.charAt(index + 1);

  //             if (ch >= 'a' && ch <= 'z' && isVowel(ch, prev, next)) {
  //                 return Number(ch.charCodeAt(0) - 'a'.charCodeAt(0)) + 1;
  //             }
  //             return (ch >= '0' && ch <= '9') ? Number(ch) : 0;
  //         }).reduce((sum, current) => sum + current, 0);
  //     }

  //     function calculateCore(str: string) {
  //         const sumBirth: number = sumCharacters(str);
  //         if (sumBirth == 0) {
  //             return -1;
  //         }
  //         return isCoreValue(sumBirth) ? sumBirth : calculateCore(String(sumBirth));
  //     }

  //     return calculateCore(str);
  // }

  function calculateNoiTam(str: string) {
    if (!str || str == "") {
      return -1;
    }
    function isCoreValue(n: number) {
      return (n >= 1 && n <= 9) || n == 11 || n == 22 || n == 33;
    }

    function sumCharacters(str: string) {
      return Array.prototype.map
        .call((str || "").toLowerCase(), (ch, index) => {
          var length = str.length;
          var prev = "";
          if (index != 0) prev = str.charAt(index - 1);

          var next = "";
          if (index != length - 1) next = str.charAt(index + 1);

          if (ch >= "a" && ch <= "z") {
            if (isVowel(ch, prev, next))
              return Number(ch.charCodeAt(0) - "a".charCodeAt(0)) + 1;
          }
          return ch >= "0" && ch <= "9" ? Number(ch) : 0;
        })
        .reduce((sum, current) => sum + current, 0);
    }

    function calculateCore(str: string) {
      if (isCoreValue(Number(str))) return str;
      const sumBirth: number = sumCharacters(str);

      if (sumBirth == 0) {
        return -1;
      }
      return isCoreValue(sumBirth) ? sumBirth : calculateCore(String(sumBirth));
    }

    return calculateCore(str);
  }

  function calculateNhanCach(str: string) {
    if (!str || str == "") {
      return -1;
    }
    function isCoreValue(n: number) {
      return (n >= 1 && n <= 9) || n == 11 || n == 22 || n == 33;
    }

    function sumCharacters(str: string) {
      let lowerCaseStr = toLC(str);
      return Array.prototype.map
        .call(lowerCaseStr, (ch, index) => {
          var length = str.length;
          var prev = "";
          if (index != 0) prev = str.charAt(index - 1);

          var next = "";
          if (index != length - 1) next = str.charAt(index + 1);

          if (ch >= "a" && ch <= "z") {
            if (!isVowel(ch, prev, next))
              return Number(ch.charCodeAt(0) - "a".charCodeAt(0)) + 1;
          }
          return ch >= "0" && ch <= "9" ? Number(ch) : 0;
        })
        .reduce((sum, current) => sum + current, 0);
    }
    function calculateCore(str: string) {
      if (isCoreValue(Number(str))) return str;
      const sumBirth: number = sumCharacters(str);

      if (sumBirth == 0) {
        return -1;
      }
      return isCoreValue(sumBirth) ? sumBirth : calculateCore(String(sumBirth));
    }

    return calculateCore(str);
  }

  function calculateThaiDo(str: string) {
    if (!str || str == "") {
      return -1;
    }
    function isCoreValue(n: number) {
      return (n >= 1 && n <= 9) || n == 11 || n == 22 || n == 33;
    }

    function sumCharacters(str: string) {
      return Array.prototype.map
        .call((str || "").toLowerCase(), (ch) => {
          if (ch >= "a" && ch <= "z") {
            return Number(ch.charCodeAt(0) - "a".charCodeAt(0)) + 1;
          }
          return ch >= "0" && ch <= "9" ? Number(ch) : 0;
        })
        .reduce((sum, current) => sum + current, 0);
    }

    function calculateCore(str: string) {
      if (isCoreValue(Number(str))) return str;
      const sumBirth: number = sumCharacters(str);

      if (sumBirth == 0) {
        return -1;
      }
      return isCoreValue(sumBirth) ? sumBirth : calculateCore(String(sumBirth));
    }

    return calculateCore(str);
  }

  function calculateNoiCam(str: string) {
    let lowerCaseStr = toLC(str);
    str = str.replace(/ /g, "");
    if (!str || str == "") {
      return "";
    }
    var arr = Array.prototype.map.call(lowerCaseStr, (ch) => {
      // return String(_calculateString(ch));
      return ch;
    });
    str = "";

    arr.forEach((element) => {
      str = str + element;
    });
    const { max, ...counts } = (str || "").split("").reduce(
      (a, c) => {
        a[c] = a[c] ? a[c] + 1 : 1;
        a.max = a.max < a[c] ? a[c] : a.max;
        return a;
      },
      { max: 0 }
    );

    var rawResults = Object.entries(counts)
      .filter(([k, v]) => v === max)
      .join(" | ")
      .replace(/,/g, ":");
    var arr1 = rawResults.split(" | ");
    arr1 = arr1.map((element) => {
      var inner_arr = element.split(":");
      return get1to9(inner_arr[0]);
    });
    return arr1
      .filter(function (x) {
        return Number(x) > -1;
      })
      .join(",");
  }

  function calculateTruongThanh(sm, dd) {
    return get1122AsResult(merge1122(sm) + merge1122(dd));
  }

  function calculateChangDuongDoi(td) {
    var cdd = changduongdoi;
    cdd[0] = get1to9(String(td));
    cdd[1] = get1to9(String(inputYear) + String(inputDay));
    cdd[2] = get1to9(String(cdd[0] + cdd[1]));
    cdd[3] = get1to9(String(inputMonth) + String(inputYear));
    return cdd;
  }

  function calculateMocTuoi(dd) {
    var mt = moctuoi;
    mt[0] = 36 - get1to9(String(duongdoi));
    mt[1] = mt[0] + 9;
    mt[2] = mt[1] + 9;
    mt[3] = mt[2] + 9;
    return mt;
  }

  function calculateSoBoSung(name) {
    //Nguyen Dang Khoi: No missing number
    var danhdo = calculateDanhDo(name);
    var checkList = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
    return checkList.filter((x) => !danhdo.includes(x)).join(", ");
  }

  function calculateSucManhTiemThuc(sbs) {
    var count = 0;
    if (sbs != "") count = sbs.split(", ").length;
    return 9 - count;
  }

  // function calculateChiSoThachThuc() {
  //     var date = String(inputDay) + String(inputMonth) + String(inputYear);
  //     var checkList = ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
  //     return checkList.filter(x => !date.includes(x)).join(', ')
  // }

  function calculateChiSoThachThuc(date: any): any[] {
    var chisothachthuc: any[] = [];
    var shortYear = get1to9(date.substring(0, 4));
    var shortMonth = get1to9(date.substring(5, 7));
    var shortDay = get1to9(date.substring(8, 10));
    chisothachthuc[0] = Math.abs(shortDay - shortMonth);
    chisothachthuc[1] = Math.abs(shortDay - shortYear);
    chisothachthuc[2] = Math.abs(chisothachthuc[0] - chisothachthuc[1]);
    chisothachthuc[3] = Math.abs(shortYear - shortMonth);
    return chisothachthuc;
  }

  function calculateSoCanBang() {
    // var a = get1to9(String(Math.abs(shortYear - shortMonth)))
    var initials = inputName
      .split(" ")
      .map((n) => n[0])
      .join("");
    return get1to9(initials);
  }

  function calculateNamCaNhan() {
    var a = get1to9(
      String(
        _calculateString(String(inputDay)) +
          _calculateString(String(inputMonth)) +
          _calculateString(String(new Date().getFullYear()))
      )
    );
    return a;
  }

  function calculateChiSoKetNoiNTNC(nt, nc) {
    return get1to9(String(Math.abs(merge1122(nt) - merge1122(nc))));
  }

  function calculateChiSoKetNoiSMDD(dd, sm) {
    return get1to9(String(Math.abs(merge1122(dd) - merge1122(sm))));
    // return get1to9(String(Math.abs(dd - sm)));
  }

  function calculateSoNgaySinh() {
    return get1to9(String(inputDay));
  }

  function calculateSoLap(a, b, c, d, e, f) {
    var arr = [a, b, c, d, e, f];

    arr = arr.map((item) => merge1122(item));

    return arr
      .sort(
        (a, b) =>
          arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
      )
      .pop();
  }

  function calculateChiSoThang(ncn) {
    var currentMonth = get1to9(String(new Date().getMonth() + 1));
    return get1to9(String(ncn + currentMonth));
  }

  function calculateChiSoThang1(ncn) {
    var currentMonth = get1to9(String(new Date().getMonth() + 2));
    return get1to9(String(ncn + currentMonth));
  }

  function calculateChiSoThang2(ncn) {
    var currentMonth = get1to9(String(new Date().getMonth() + 3));
    return get1to9(String(ncn + currentMonth));
  }

  function calculateChiSoNgay(tcn) {
    var currentDay = get1to9(String(new Date().getDate()));
    return get1to9(String(tcn + currentDay));
  }

  function calculateTuDuyLyTri() {
    var num = _calculateString(
      String(_calculateString(inputFirstName) + _calculateString(inputDayStr))
    );
    return num;
  }

  async function getProfiles(name) {
    class attr {
      AttributeName: string;
      AttributeID: number;

      constructor(AttributeName: string, AttributeID: number) {
        this.AttributeName = AttributeName;
        this.AttributeID = AttributeID;
      }
    }
    let attributes: attr[] = [];

    if (name == "all") {
      attributes.push(new attr("DuongDoi", duongdoi));
      attributes.push(new attr("SuMenh", sumenh));
      attributes.push(new attr("DuongDoiSuMenh", chisoketnoiSMDD));
      attributes.push(new attr("NoiTam", noitam));
      attributes.push(new attr("SoNgaySinh", songaysinh));
      attributes.push(new attr("SoCanBang", socanbang));
      attributes.push(new attr("NhanCach", nhancach));
      attributes.push(new attr("NoiTamNhanCach", chisoketnoiNTNC));
      attributes.push(new attr("TruongThanh", truongthanh));
      attributes.push(new attr("ChangDuongDoiDinh1", changduongdoi[0]));
      attributes.push(new attr("ChangDuongDoiDinh2", changduongdoi[1]));
      attributes.push(new attr("ChangDuongDoiDinh3", changduongdoi[2]));
      attributes.push(new attr("ChangDuongDoiDinh4", changduongdoi[3]));
      attributes.push(new attr("SoBoSung", 1));
      attributes.push(new attr("SucManhTiemThuc", sucmanhtiemthuc));
      attributes.push(new attr("TuDuyLyTri", tuduylytri));
      attributes.push(new attr("ThaiDo", thaido));
      var arr = noicam.split(",");
      arr.forEach((item) =>
        attributes.push(new attr("NoiCam", parseInt(item)))
      );
      // attributes.push(new attr('SoLap', 1))
      attributes.push(new attr("ChiSoNamCaNhan", namcanhan));

      // var arr = chisothachthuc.split(',');
      // arr.forEach((item) => attributes.push(new attr('ChiSoThachThuc', parseInt(item))))
      attributes.push(new attr("ChiSoThang", chisothang));
      // attributes.push(new attr('ChiSoThang', chisothang1))
      // attributes.push(new attr('ChiSoThang', chisothang2))
      attributes.push(new attr("ChiSoNgay", chisongay));
      chisothachthuc.forEach((item) =>
        attributes.push(new attr("ChiSoThachThuc", parseInt(item)))
      );
    } else {
      if (name == "DuongDoi") attributes.push(new attr(name, duongdoi));
      if (name == "NoiTam") attributes.push(new attr(name, noitam));
      if (name == "NhanCach") attributes.push(new attr(name, nhancach));
      if (name == "SuMenh") attributes.push(new attr(name, sumenh));
      if (name == "ThaiDo") attributes.push(new attr(name, thaido));
      if (name == "NoiCam") {
        var arr = noicam.split(",");
        arr.forEach((item) => attributes.push(new attr(name, parseInt(item))));
      }
      if (name == "SoNgaySinh") {
        attributes.push(new attr(name, songaysinh));
      }
      // if (name == 'SoLap') {
      //     attributes.push(new attr(name, 1))
      // }
      if (name == "TruongThanh") {
        attributes.push(new attr(name, truongthanh));
      }
      if (name == "ChangDuongDoiDinh1") {
        attributes.push(new attr(name, changduongdoi[0]));
      }
      if (name == "ChangDuongDoiDinh2") {
        attributes.push(new attr(name, changduongdoi[1]));
      }
      if (name == "ChangDuongDoiDinh3") {
        attributes.push(new attr(name, changduongdoi[2]));
      }
      if (name == "ChangDuongDoiDinh4") {
        attributes.push(new attr(name, changduongdoi[3]));
      }
      if (name == "SoBoSung") {
        attributes.push(new attr(name, 1));
      }
      if (name == "NoiTamNhanCach") {
        attributes.push(new attr(name, chisoketnoiNTNC));
      }
      if (name == "DuongDoiSuMenh") {
        attributes.push(new attr(name, chisoketnoiSMDD));
      }
      if (name == "ChiSoNamCaNhan") {
        attributes.push(new attr(name, namcanhan));
      }
      if (name == "ChiSoThang") {
        attributes.push(new attr(name, chisothang));
      }
      if (name == "SoCanBang") {
        attributes.push(new attr("SoCanBang", socanbang));
      }
      if (name == "ChiSoThang1") {
        attributes.push(new attr("ChiSoThang", chisothang1));
      }

      if (name == "ChiSoThang2") {
        attributes.push(new attr("ChiSoThang", chisothang2));
      }

      if (name == "ChiSoNgay") {
        attributes.push(new attr("ChiSoNgay", chisothang1));
      }
      if (name == "ChiSoThachThuc") {
        // var arr = chisothachthuc.split(',');
        chisothachthuc.forEach((item) =>
          attributes.push(new attr("ChiSoThachThuc", parseInt(item)))
        );
      }
      if (name == "SucManhTiemThuc")
        attributes.push(new attr("SucManhTiemThuc", sucmanhtiemthuc));
      if (name == "TuDuyLyTri")
        attributes.push(new attr("TuDuyLyTri", tuduylytri));
    }

    return await axios
      .post(api_url + "/getProfilesByAttributeRuka", attributes)
      .then(function (response) {
        if (name != "all") {
          if (name == "ChiSoThang1" || name == "ChiSoThang2")
            name = "ChiSoThang";
          var data = response.data;
          var noicam_list: any[] = [];
          var profile = data.find((x) => x.AttributeName == name);

          if (profile) setProfile(profile.Text);
          else {
            setOpen(true);
            setTimeout(() => setOpen(false), 10000);
          }

          if (name == "NoiCam") {
            noicam_list = data.filter((obj) => {
              return obj.AttributeName === "NoiCam";
            });
            noicam_list = noicam_list.map((obj) => {
              if (obj) return obj.Text;
              else return "";
            });
            setProfile(noicam_list.join(`\r\n\r\n`));
          }

          if (name == "ChiSoThachThuc") {
            var list = data.filter((obj) => {
              return obj.AttributeName === "ChiSoThachThuc";
            });
            list = list.map((obj) => {
              if (obj) return obj.Text;
              else return "";
            });
            setProfile(list.join(`\r\n\r\n`));
          }
        } else {
          var data = response.data;
          var all_text = "";
          data.forEach((item) => {
            if (item) return (all_text += item.Text + "\r\n\r\n");
            else return "";
          });
          setProfile(all_text);
          return all_text;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleExportToWord = async () => {
    getProfiles("all").then(async (returned_profile) => {
      const introRun = new docx.TextRun({
        text: `Báo Cáo Thần Số Học Của Bạn: `,
        italics: true,
      });
      const nameRun = new docx.TextRun({
        text: `${inputName}`,
      });
      const profilesParagraphs = [
        new docx.Paragraph({
          children: [introRun, nameRun],
        }),
        new docx.Paragraph(
          `Ngày sinh: ${moment(inputBirth).format("DD/MM/YYYY")}`
        ),
        new docx.Paragraph(``),
        new docx.Paragraph(``),
      ];
      if (returned_profile)
        returned_profile.split("\n").forEach((item) => {
          console.log("item", item);
          if (item) {
            profilesParagraphs.push(new docx.Paragraph(item));
          }
        });
      else {
        console.log("Failed to save Word");
        return 0;
      }
      profilesParagraphs.push(
        new docx.Paragraph(``),
        new docx.Paragraph(
          `Ngày xuất báo cáo: ${moment(new Date()).format(
            "DD/MM/YYYY hh:mm:ss"
          )}`
        )
      );
      const doc = await new docx.Document({
        sections: [
          {
            properties: {},
            children: profilesParagraphs,
          },
        ],
      });

      const blob = await docx.Packer.toBlob(doc);

      const element = document.createElement("a");
      element.href = URL.createObjectURL(blob);
      element.download = `${inputBirth} - ${inputName}.docx`;
      document.body.appendChild(element);
      element.click();
    });
  };

  return (
    <div
      style={{ textAlign: "center", fontSize: 12 }}
      className="calculator-container"
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <div style={{ marginTop: 1, marginBottom: 1 }}>Nhập ngày sinh:</div>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
            <div>
              <DatePicker
                value={inputBirth}
                onChange={_onChangeBirth}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    id="standard-size-normal"
                    variant="standard"
                    sx={{ width: 170 }}
                  />
                )}
              />
            </div>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: 1, marginBottom: 1 }}>
            Nhập họ và tên lót
          </div>
          <input
            type="text"
            name="input-last-name"
            id="input-last-name"
            value={inputLastName}
            onChange={_onChangeLastName}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: 1, marginBottom: 1 }}>Nhập tên</div>
          <input
            type="text"
            name="input-first-name"
            id="input-first-name"
            value={inputFirstName}
            onChange={_onChangeFirstName}
          />
        </Grid>
      </Grid>

      <Button
        onClick={() => getProfiles("all")}
        style={{ marginTop: 15, marginBottom: 15 }}
        variant="outlined"
      >
        Báo cáo
      </Button>
      <Button
        onClick={() => handleExportToWord()}
        style={{ marginTop: 15, marginLeft: 20, marginBottom: 15 }}
        variant="outlined"
      >
        Xuất Word
      </Button>

      <div style={{ fontSize: 30 }}></div>

      <section>
        <a href="#" onClick={() => getProfiles("DuongDoi")}>
          Đường Đời: {duongdoi != -1 ? (duongdoi == 1 ? 10 : duongdoi) : ""}
        </a>
      </section>

      <section>
        <a href="#" onClick={() => getProfiles("SuMenh")}>
          Sứ Mệnh: {sumenh != -1 ? sumenh : ""}
        </a>
      </section>

      <section>
        <a href="#" onClick={() => getProfiles("DuongDoiSuMenh")}>
          Chỉ số kết nối giữa Đường đời và Sứ mệnh:{" "}
          {chisoketnoiSMDD != -1 ? chisoketnoiSMDD : ""}
        </a>
      </section>

      <section>
        <a href="#" onClick={() => getProfiles("NoiTam")}>
          Linh Hồn: {noitam != -1 ? noitam : ""}
        </a>
      </section>
      <section>
        <a href="#" onClick={() => getProfiles("SoNgaySinh")}>
          Số ngày sinh: {songaysinh != -1 ? songaysinh : ""}
        </a>
      </section>

      <section>
        <a href="#" onClick={() => getProfiles("SoCanBang")}>
          Số cân bằng: {socanbang}
        </a>
      </section>

      <section>
        <a href="#" onClick={() => getProfiles("NhanCach")}>
          Nhân Cách: {nhancach != -1 ? nhancach : ""}
        </a>
      </section>

      <section>
        <a href="#" onClick={() => getProfiles("NoiTamNhanCach")}>
          Chỉ số kết nối giữa Linh hồn và Nhân cách:{" "}
          {chisoketnoiNTNC != -1 ? chisoketnoiNTNC : ""}
        </a>
      </section>

      <section>
        <a href="#" onClick={() => getProfiles("TruongThanh")}>
          Trưởng Thành: {truongthanh != -1 ? truongthanh : ""}
        </a>
      </section>

      <section>
        <div style={{ marginTop: 5 }}>
          Chặng Đường Đời:{" "}
          {changduongdoi.map((element, index) => {
            if (element != -1)
              if (index != 0)
                return (
                  <div>
                    <a
                      href="#"
                      onClick={() =>
                        getProfiles("ChangDuongDoiDinh" + String(index + 1))
                      }
                    >
                      Đỉnh {index + 1}: {String(element)}. Mốc tuổi:{" "}
                      {moctuoi[index]}.
                    </a>{" "}
                    <a href="#" onClick={() => getProfiles("ChiSoThachThuc")}>
                      Thách thức: {chisothachthuc[index]}
                    </a>
                  </div>
                );
              else
                return (
                  <div>
                    <a
                      href="#"
                      onClick={() =>
                        getProfiles("ChangDuongDoiDinh" + String(index + 1))
                      }
                    >
                      Đỉnh 1: {element}. Mốc tuổi: {moctuoi[index]}.
                    </a>
                    <a href="#" onClick={() => getProfiles("ChiSoThachThuc")}>
                      {" "}
                      Thách thức: {chisothachthuc[index]}
                    </a>
                  </div>
                );
          })}
        </div>
      </section>

      <section>
        <a href="#" onClick={() => getProfiles("SoBoSung")}>
          Số thiếu: {sobosung}
        </a>
      </section>
      <section>
        <a href="#" onClick={() => getProfiles("SucManhTiemThuc")}>
          Sức Mạnh Tiềm Thức: {sucmanhtiemthuc}
        </a>
      </section>
      <section>
        <a href="#" onClick={() => getProfiles("TuDuyLyTri")}>
          Tư Duy Lý Trí: {tuduylytri}
        </a>
      </section>
      <section>
        <a href="#" onClick={() => getProfiles("ThaiDo")}>
          Thái Độ: {thaido != -1 ? thaido : ""}
        </a>
      </section>
      <section>
        <a href="#" onClick={() => getProfiles("NoiCam")}>
          Đam Mê: {noicam != "" ? noicam : ""}
        </a>
      </section>
      {/* 
                    <section>
                        <a href="#" onClick={() => getProfiles('NoiCam')}>Nội Cảm: {noicam != '' ? noicam : ''}</a>
                    </section> */}

      {/* 
                    <section>
                        <a href="#" onClick={() => getProfiles('SoLap')}>Số lặp: {solap != -1 ? solap : ''}</a>
                    </section> */}

      {/* 
                    <section>
                        <a href="#" onClick={() => getProfiles('ChiSoThachThuc')}>Chỉ số thách thức: {chisothachthuc}</a>
                    </section> */}

      <section>
        <a href="#" onClick={() => getProfiles("ChiSoNamCaNhan")}>
          Chỉ số Năm cá nhân: {namcanhan != -1 ? namcanhan : ""}{" "}
        </a>
      </section>

      <section>
        <a href="#" onClick={() => getProfiles("ChiSoThang")}>
          Chỉ số Tháng cá nhân: {chisothang != -1 ? chisothang : ""}
        </a>
      </section>
      {/* 
                    <section>
                        <a href="#" onClick={() => getProfiles('ChiSoThang1')}>Chỉ số tháng sau: {chisothang1 != -1 ? chisothang1 : ''}</a>
                    </section>

                    <section>
                        <a href="#" onClick={() => getProfiles('ChiSoThang2')}>Chỉ số tháng sau nữa: {chisothang2 != -1 ? chisothang2 : ''}</a>
                    </section> */}

      <section>
        <a href="#" onClick={() => getProfiles("ChiSoNgay")}>
          Chỉ số Ngày cá nhân: {chisongay != -1 ? chisongay : ""}
        </a>
      </section>
      <TextField
        sx={{ width: "80%", marginBottom: 5, marginTop: 10 }}
        value={profile}
        multiline
      />
    </div>
  );
}

export default Calculator;
